import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import { SEO } from "../common/components/seo";
import { P } from "../common/components/typography/p";

export interface INotFoundPage {}

const PageWrapper = styled.div({
  textAlign: "center",
  paddingTop: 64,
  paddingBottom: 64,
});

const NotFoundPage: React.SFC<INotFoundPage> = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <PageWrapper>
      <h1>Sorry!</h1>
      <P>We can't find that page</P>
    </PageWrapper>
  </Layout>
);

export default NotFoundPage;
